import { Dialog, Transition } from "@headlessui/react"
import {
  CalendarDaysIcon,
  CircleStackIcon,
  FunnelIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid"
import axios from "axios"
import {
  Button,
  Container,
  Feed,
  Feeds,
  Loading,
  Modal,
  Notification,
} from "blixify-ui-web"
import { navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import SEO from "../components/base/seo"
import DashboardLogo from "../images/dashboard_logo.png"
import TNGHome from "../images/tng_home.png"
import { signIn } from "../store/actions/authActions"
import { updateTNGAccess } from "../store/actions/utilsActions"
import { authStateInterface } from "../store/reducers/authReducer"
import { utilsStateInterface } from "../store/reducers/utilsReducer"
import { apiPrefix } from "../utils/clientQuery"

interface Props {
  authStore: authStateInterface
  utilsStore: utilsStateInterface
  updateTNGAccess: (tngAccess: boolean) => void
}

class Home extends Component<Props> {
  state = {
    loading: false,
    errorNotification: false,
  }

  componentDidMount = () => {
    this.props.updateTNGAccess(true)
    this.handleCheckAuthNavigator()

    try {
      window.my.onMessage = async (e: any) => {
        try {
          const authCode = e?.authCode
          if (authCode) {
            this.setState({ loading: true })
            const tngResp = await axios.post(apiPrefix + "/api/tng", {
              authCode: authCode,
            })
            if (tngResp.data) {
              const walletData = tngResp.data?.data
              await signIn(
                {
                  email: walletData.email,
                  password: walletData.walletId,
                },
                this.props.utilsStore.firebase
              )
              //eslint-disable-next-line
            } else throw "Error"
          }
        } catch (err) {
          this.setState({ errorNotification: true })
        }
        this.setState({ loading: false })
      }
    } catch (err) {}
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.authStore.userLoading !== this.props.authStore.userLoading ||
      prevProps.authStore.userAuth !== this.props.authStore.userAuth ||
      prevProps.authStore.user !== this.props.authStore.user
    ) {
      this.handleCheckAuthNavigator()
    }
  }

  componentWillUnmount = () => {}

  handleCheckAuthNavigator = async () => {
    if (this.props.authStore.userAuth) {
      if (this.props.authStore.user) navigate("/auth/signUp")
      else navigate(this.props.utilsStore.tngAccess ? "/tngHome" : "/dashboard")
    }
  }

  handleAuthenticateTNG = async () => {
    try {
      window.my.postMessage({ name: "TNG" })
    } catch (err) {}
  }

  renderFeedsContent = () => {
    const recycleStepsContent: Feed[] = [
      {
        iconType: <FunnelIcon className="h-5 w-5 text-white" />,
        iconClass:
          "bg-primary-600 border border-gray-300 flex justify-center items-center",
        content: "Fill in your Appointment details",
        step: "Step 1",
        addContent: (
          <p className="text-gray-500 max-w-xl">
            Provide photos and details of used cooking oil(UCO), our team will
            be collecting from your house/business premise.
          </p>
        ),
      },
      {
        iconType: <CircleStackIcon className="h-5 w-5 text-white" />,
        iconClass:
          "bg-primary-600 border border-gray-300 flex justify-center items-center",
        content: "Receive Exact Time & Date of collection in 1-3 working days ",
        step: "Step 2",
        addContent: (
          <p className="text-gray-500 max-w-xl">
            Our customer service team will be contacting you to confirm the pick
            up arrangement
          </p>
        ),
      },
      {
        iconType: <CalendarDaysIcon className="h-5 w-5 text-white" />,
        iconClass:
          "bg-primary-600 border border-gray-300 flex justify-center items-center",
        content: "Receive cashback from recycling used cooking oil(UCO)",
        step: "Step 3",
        addContent: (
          <p className="text-gray-500 max-w-xl">
            Buy-back/Cashback amount for used cooking oil will be deposited into
            your TNG E-Wallet
          </p>
        ),
      },
    ]
    return recycleStepsContent
  }

  render() {
    return (
      <>
        <Notification
          icon={<XCircleIcon className="w-10 h-10 text-primary-600" />}
          notificationAttribute={{
            title: "Sign In Failed",
            desc: "Please contact info@arusoil.com if this error exists again",
            visible: this.state.errorNotification,
          }}
          lib={{
            Transition,
          }}
          onClose={() => {
            this.setState({ errorNotification: false })
            return ""
          }}
        />
        <Container
          className="py-10 sm:py-5 min-h-screen"
          bgColor={"bg-green-50"}
        >
          <SEO title="Arus Oil" />
          <Modal
            open={this.state.loading}
            lib={{
              Dialog,
              Transition,
            }}
            renderContent={() => {
              return (
                <div className="flex flex-col items-center">
                  <Loading />
                  <p className="text-sm text-gray-700 mt-2">
                    Please hold on while we are verifying your account
                  </p>
                </div>
              )
            }}
            onClose={() => {}}
          />
          <img
            className="block h-10 w-auto"
            src={DashboardLogo}
            alt="Arus Oil Logo"
          />
          <h1 className="mt-5 text-2xl font-bold leading-tight text-gray-900">
            Recycling Used Cooking Oil Made Easy
          </h1>
          <div className="bg-white rounded-xl mt-8 p-6">
            <div className="w-full flex flex-col items-center justify-center">
              <img alt="" src={TNGHome} className="w-52 h-52 obj-cover" />
              <h2 className="text-xl font-extrabold text-arusgreen-800">
                Earn Money by Recycling Used Cooking Oil (UCO)
              </h2>
              <p className="text-base font-bold text-gray-700 mb-2">
                Save the environment for a more sustainable future - UCO to
                Biodiesel
              </p>
            </div>
            <h2 className="text-xl font-extrabold text-arusgreen-800 mt-6 mb-2">
              Steps To Recycle
            </h2>
            <Feeds content={this.renderFeedsContent()} bold={true} />
          </div>
          <div className="mt-8 w-full flex justify-end">
            <Button
              text="Get Started"
              type="normal"
              onClick={() => {
                this.handleAuthenticateTNG()
              }}
            />
          </div>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilsStore: state.utilsStore,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTNGAccess: (tngAccess: boolean) =>
      dispatch(updateTNGAccess(tngAccess)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
